<template>
  <Content>
    <template #contentTitle>
      {{ $t("warehouse.storage_warehouse_in_plan") }}
    </template>
    <template v-slot:contentBody="wrap">
      <a-row :gutter="[16, 16]" type="flex" justify="space-between" align="bottom">
        <a-col flex="1">
          <a-row :gutter="[16, 16]">
            <a-col>
              <a-input style="width: 250px"
                       v-model:value="searchForm.plan"
                       :placeholder="`${$t('warehouse.plan_name')}/${$t('warehouse.in_warehouse_no')}`"
                       allowClear />
            </a-col>
            <a-col>
              <a-input style="width: 250px"
                       v-model:value="searchForm.box"
                       :placeholder="`${$t('warehouse.box_no')}/${$t('common.user_defined_box_no')}`"
                       allowClear />
            </a-col>
             <a-col>
            <SearchProduct ref="refSearchProduct"></SearchProduct>
           </a-col>
            <a-col>
              <a-select v-model:value="searchForm.warehouseId"
                        :placeholder="$t('warehouse.target_warehouse')"
                        allow-clear
                        :showSearch="true"
                        optionFilterProp="search-key"
                        style="width: 250px">
                <a-select-option v-for="(value, key) in targetWarehouseList"
                                 :key="key"
                                 :value="value.id"
                                 :search-key="value.warehouseNo+value.warehouseName"
                                 :title="value.warehouseNo+'('+value.warehouseName+')'">
                  {{ value.warehouseNo }} ({{value.warehouseName}})
                </a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-select v-model:value="searchForm.inWarehouseType"
                        :placeholder="$t('warehouse.in_warehouse_type')"
                        allow-clear
                        style="width: 250px">
                <a-select-option v-for="(value, key) in inWarehouseTypeEnum"
                                 :key="key"
                                 :value="value"
                                 :title="$t($enumLangkey('inWarehouseType', value))">
                  {{ $t($enumLangkey("inWarehouseType", value)) }}
                </a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-select v-model:value="searchForm.inWarehouseStatus"
                        :placeholder="$t('warehouse.status')"
                        allow-clear
                        style="width: 250px">
                <a-select-option v-for="(value, key) in inWarehouseStatusEnum"
                                 :key="key"
                                 :value="value"
                                 :title="$t($enumLangkey('inWarehouseStatus', value))">
                  {{ $t($enumLangkey("inWarehouseStatus", value)) }}
                </a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-range-picker style="width: 250px"
                              format="YYYY-MM-DD"
                              @change="handleConfirmDate" />
            </a-col>
            <a-col>
              <a-button type="primary" @click="handleChangeStatus">{{ $t('common.query') }}</a-button>
            </a-col>
            <a-col>
              <a-button type="ghost" :loading="exportLoading" @click="handleExport">{{ $t('common.export') }}</a-button>
            </a-col>
          </a-row>
        </a-col>
        <a-col>
          <a-button type="primary"
                    @click="handleCreateInPlan"
                    ghost>{{ $t("common.create") }}</a-button>
        </a-col>
      </a-row>
      <div class="inventory-table mt-3">
        <a-table :columns="columns"
                 size="small"
                 :data-source="planList"
                 :scroll="{ x: 400, y: wrap.contentHeight - 170 }"
                 :pagination="false"
                 :loading="planListLoading"
                 class="product-table">
          <template #planNameAndNo>
            <div>{{ $t("warehouse.plan_name") }}</div>
            <div>{{ $t("warehouse.in_warehouse_no") }}</div>

          </template>

          <template #relation>
            <div>{{ $t("warehouse.source_related_doc_no") }}</div>
            <div>{{ $t("warehouse.target_related_doc_no") }}</div>
          </template>
          <template #relationV="{ record }">
            <div><CPlanNumberItem :no="record.relation"></CPlanNumberItem></div>
            <div><CPlanNumberItem :no="record.toRelationPlanNo"></CPlanNumberItem></div>
          </template>
          <template #targetWarehouse>
            <span>
              {{ $t("warehouse.target_warehouse") }}
            </span>
          </template>

          <template #inWarehouseType>
            <span>
              {{ $t("warehouse.in_warehouse_type") }}
            </span>
          </template>
          <template #plannedQuantity>
            <span>
              {{ $t("warehouse.planned_quantity") }}
            </span>
          </template>
          <template #plannedBoxQuantity>
            <span>
              {{ $t("warehouse.planned_box_quantity") }}
            </span>
          </template>
          <template #productQuantity>
            <span>
              {{ $t("warehouse.product_quantity") }}
            </span>
          </template>
          <template #receiveBoxQuantity>
            <span>
              {{ $t("warehouse.receive_box_quantity") }}
            </span>
          </template>
          <template #inPlanStatus>
            <span>
              {{ $t("warehouse.status") }}
            </span>
          </template>
          <template #creationTime>
            <span>
              {{ $t("warehouse.creation_time") }}
            </span>
          </template>
          <template #updateTime>
            <span>
              {{ $t("warehouse.update_time") }}
            </span>
          </template>
          <template #subsequentStepsTitle>
            <span>
              {{ $t("warehouse.subsequent_steps") }}
            </span>
          </template>

          <template #planNameAndNoCustom="{ record }">
            <div>{{ record.planName }}</div>
            <CPlanNumberItem  :no="record.planNo"></CPlanNumberItem>
          </template>

          <template #inWarehouseTypeCustom="{ record }">
            <span>
              {{ $t($enumLangkey("inWarehouseType", record.inWarehouseType)) }}
            </span>
          </template>

          <template #planStatusCustom="{ record }">
            {{ $t($enumLangkey("inWarehouseStatus", record.inPlanStatus)) }}
            <span v-if="record.hasException" class="ml-1">
              <a-tag color="default" v-if="record.inPlanStatus === inWarehouseStatusEnum.complete || record.inPlanStatus === inWarehouseStatusEnum.cancel">
                {{ $t("common.exception") }}
              </a-tag>
              <a-tag color="error" v-else>{{ $t("common.exception") }}</a-tag>
            </span>
          </template>

          <template #creationTimeCustom="{ record }">
            {{ $filters.utcToCurrentTime(record.creationTime) }}
          </template>
          <template #updateTimeCustom="{ record }">
            {{ $filters.utcToCurrentTime(record.updateTime) }}
          </template>

          <template #operate="{ record }">
            <a-dropdown>
              <template #overlay>
                <a-menu @click="handleMenuClick">
                  <a-menu-item key="1" :record="record" v-if="!record.isConfirm">
                    {{ $t("warehouse.dispose_in_warehouse_plan") }}
                  </a-menu-item>
                  <a-menu-item key="2" :record="record" v-else-if="!record.isDeliver">
                    {{ $t("warehouse.send_shipping_in_warehouse_plan") }}
                  </a-menu-item>
                  <a-menu-item key="3" :record="record" v-else-if="record.isNeedUserOperation">
                    {{ $t("warehouse.need_operate") }}
                  </a-menu-item>
                  <a-menu-item key="4" :record="record" v-else>
                    {{ $t("warehouse.plan_details") }}
                  </a-menu-item>
                  <a-menu-item key="5" :record="record" v-if="record.isCanCancel">
                    {{ $t("common.cancel") }}
                  </a-menu-item>
                  <a-menu-item key="6" :record="record" :disabled="!record.isCanCreateCws">
                    {{ $t("menu.transport_create_plan") }}
                  </a-menu-item>
                </a-menu>
              </template>
              <a-button>
                {{ $t('common.operation') }}
                <DownOutlined />
              </a-button>
            </a-dropdown>
          </template>
        </a-table>
      </div>
      <CPlanCanceledAfterModal ref="cPlanCanceledAfterModalRef" />
      <CompleteProductInformationModal
        v-model:visible="completeProductModalState.visible"
        :warehouse-type="warehouseTypeEnum.storageWarehouse"
        :plan-id="completeProductModalState.planId"
        :items="completeProductModalState.items"
        @refresh="showJumpToTransportModal"
      />
    </template>
    <template #contentFooter>
      <a-row type="flex"
             justify="space-around"
             align="middle">
        <a-col>
          <CPager @handlePage="handlePage"
                  :page-data="pageData"></CPager>
        </a-col>
      </a-row>
    </template>
  </Content>
</template>

<script>
import { defineComponent, reactive, toRefs, onActivated, ref } from "vue";
import {
  Row,
  Col,
  Tag,
  Table,
  Input,
  Select,
  Button,
  Form,
  Space,
  DatePicker,
  Dropdown,
  Menu,
  Modal,
  message,
} from "ant-design-vue";
import Content from "../../components/Content.vue";
import CPager from "../../components/CPager.vue";
import CPlanNumberItem from "../../components/CPlanNumberItem.vue";
import CPlanCanceledAfterModal from "@/views/components/CPlanCanceledAfterModal.vue";
import SearchProduct from "../../components/SearchProduct.vue";
import CompleteProductInformationModal from "./components/CompleteProductInformationModal.vue";

import { getInPlans, getBetweenWarehouse, updateCancelPlan, exportStorageInWarehousePlan } from "../../../api/modules/storage/index";
import { checkIsNeedProduct, createTransportPlanByUnNeedProduct } from '@/api/modules/transportation/index.js';
import {
  inWarehouseStatus as inWarehouseStatusEnum,
  inWarehouseType as inWarehouseTypeEnum,
  warehouseType as warehouseTypeEnum,
} from "../../../enum/enum.json";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n/index";
import { currentTimeToUtc, dateString } from "../../../utils/general";
import { downloadFile } from '@/utils/downloader.js';

export default defineComponent({
  name: "storage_inbound_list",
  components: {
    ARow: Row,
    ACol: Col,
    ATable: Table,
    AInput: Input,
    ASelect: Select,
    AButton: Button,
    ASelectOption: Select.Option,
    AFormItem: Form.Item,
    AForm: Form,
    ATag: Tag,
    ASpace: Space,
    ARangePicker: DatePicker.RangePicker,
    ADropdown: Dropdown,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    Content,
    CPager,
    CPlanNumberItem,
    CPlanCanceledAfterModal,
    SearchProduct,
    CompleteProductInformationModal,
  },
  setup () {
    //#region columns
    const columns = [
      {
        slots: {
          title: "planNameAndNo",
          customRender: "planNameAndNoCustom",
        },
        fixed: "left",
        width: 200,
      },
      {
        slots: {
          title: "relation",
          customRender: "relationV",
        },
        width: 200,
      },
      {
        dataIndex: "targetWarehouse",
        slots: {
          title: "targetWarehouse",
        },
        width: 150,
      },
      {
        dataIndex: "inWarehouseType",
        slots: {
          title: "inWarehouseType",
          customRender: "inWarehouseTypeCustom",
        },
        width: 130,
      },
      {
        dataIndex: "plannedQuantity",
        slots: {
          title: "plannedQuantity",
        },
        width: 120,
      },
      {
        dataIndex: "plannedBoxQuantity",
        slots: {
          title: "plannedBoxQuantity",
        },
        width: 100,
      },
      {
        dataIndex: "productQuantity",
        slots: {
          title: "productQuantity",
        },
        width: 100,
      },
      {
        dataIndex: "receiveBoxQuantity",
        slots: {
          title: "receiveBoxQuantity",
        },
        width: 100,
      },
      {
        dataIndex: "inPlanStatus",
        slots: {
          title: "inPlanStatus",
          customRender: "planStatusCustom",
        },
        width: 180,
      },
      {
        dataIndex: "creationTime",
        slots: {
          title: "creationTime",
          customRender: "creationTimeCustom",
        },
        width: 150,
      },
      {
        dataIndex: "updateTime",
        slots: {
          title: "updateTime",
          customRender: "updateTimeCustom",
        },
        width: 170,
      },
      {
        dataIndex: "subsequentSteps",
        slots: {
          title: "subsequentStepsTitle",
          customRender: "operate",
        },
        fixed: "right",
        width: 170,
      },
    ];
    //#endregion
    const router = useRouter();
    const vueI18n = useI18n({ useScope: "global" });

    const refPage = ref();
    const refSearchProduct = ref();
    const cPlanCanceledAfterModalRef = ref();

    const state = reactive({
      inWarehouseTypeEnum,
      warehouseTypeEnum,
      planListLoading: false,
      targetWarehouseList: [],
      exportLoading: false,
      completeProductModalState: {
        visible: false,
        planId: null,
        items: [],
      }
    });

    const data = reactive({
      searchForm: {
        plan: null,
        box: null,
        warehouseId: null,
        inWarehouseStatus: null,
        inWarehouseType: null,
        beginCreationTime: null,
        endCreationTime: null,
      },
      searchFormCache: {},
      planList: [],
      pageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 10,
      },
    });

    const handleExport = () => {
      state.exportLoading = true
      let url = exportStorageInWarehousePlan();
      const searchState = Object.assign({}, data.searchFormCache)
      downloadFile(url, `ws_inbound_plan_${dateString()}.csv`, "POST", searchState).then(() => {
        message.success(vueI18n.t("common.succeed"))
      }).catch(() => { }).finally(() => {
        state.exportLoading = false
      })
    }

    const handlePage = async (pageData) => {
      data.pageData.skipCount = pageData.skipCount;
      data.pageData.maxResultCount = pageData.maxResultCount;
      getList();
    };

    const initData = () => {
      data.pageData.skipCount = 0;
      data.pageData.currentIndex = 1;
      getList();
    }

    const handleChangeStatus = () => {
      Object.assign(data.searchFormCache, data.searchForm)
      initData();
    };

    const getList = () => {
      state.planListLoading = true;
      data.searchFormCache.productId= refSearchProduct.value.selectedProductId;
      const searchData = Object.assign({}, data.pageData, data.searchFormCache, {
        warehouseType: '1'
      })
      getInPlans(searchData)
        .then((res) => {
          if (res.result) {
            let { items, totalCount } = res.result;
            data.planList = items.map((x) => {
              return {
                key: x.id,
                planName: x.planName,
                planNo: x.planNo,
                targetWarehouse: x.toInWarehouseNo,
                inWarehouseType: x.inWarehouseType,
                plannedQuantity: x.total.plan.totalWarehouseCount,
                plannedBoxQuantity: x.total.plan.totalContainerBoxCount,
                productQuantity: x.total.plan.totalCount,
                receiveBoxQuantity: x.total.check.totalContainerBoxCount,
                inPlanStatus: x.inWarehouseStatus,
                creationTime: x.creationTime,
                relation: x.relation,
                toRelationPlanNo: x.toRelationPlanNo,
                updateTime: x.lastModificationTime,
                hasException: x.hasException,
                isNeedUserOperation: x.isNeedUserOperation,
                isDeliver: x.isDeliver, //计划是否已发运
                isConfirm: x.isConfirm, //计划是否已确认
                isCanCancel: x.isCanCancel, //计划是否可以取消
                isCanCreateCws: x.isCanCreateCws, // 是否可以创建运输计划
              };
            });
            data.pageData.totalCount = parseInt(totalCount);
          } else {
            data.pageData.totalCount = 0;
          }
          state.planListLoading = false;
        })
        .catch(() => {
          state.planListLoading = false;
        });
    }

    const getTargetWarehouseList = () => {
      getBetweenWarehouse({ isActive: true, warehouseType: '1' }).then((res) => {
        let { result } = res
        if (result) {
          state.targetWarehouseList = result
        }
      }).catch()
    }

    const handleCreateInPlan = async () => {
      //跳转路由
      router.push({ path: "/storage/inbound/create" });
    };

    const handleConfirmDate = (e) => {
      data.searchForm.beginCreationTime = currentTimeToUtc(e[0]?.format("YYYY-MM-DD 00:00:00"));
      data.searchForm.endCreationTime = currentTimeToUtc(e[1]?.format("YYYY-MM-DD 00:00:00"));
    }

    const showJumpToTransportModal = (id) => {
      getList();
      Modal.confirm({
        title: vueI18n.t("common.operation"),
        content: vueI18n.t("warehouse.jump_to_transport_plan_prompt"),
        okText: vueI18n.t("common.immediate_processing"),
        cancelText: vueI18n.t("common.process_later"),
        onOk: () => {
          router.push({ path: "/transport/setplaninfo/" + id });
        },
      });
    }

    // 验证是否可以创建运输计划
    const handleValidationCanCreateTransport = async (record) => {
      let loading = message.loading(vueI18n.t('loading...', 0));
      try {
        let { result: checkRes } = await checkIsNeedProduct({ planId: record.key, warehouseType: warehouseTypeEnum.storageWarehouse });
        let { isNeedCompleteProduct, items } = checkRes;
        if (isNeedCompleteProduct) {
          state.completeProductModalState.visible = true;
          state.completeProductModalState.planId = record.key;
          state.completeProductModalState.items = items.map(item => {
            item.items.forEach(child => {
              child.id = child.productId;
              child.imgUrl = child.productImgUrl;
              child.parentRowKey = item.detailId;
            });
            return item;
          });
        } else {
          let { result: id } = await createTransportPlanByUnNeedProduct({ planId: record.key, warehouseType: warehouseTypeEnum.storageWarehouse });
          message.success(vueI18n.t('common.succeed'));
          showJumpToTransportModal(id);
        }
      } catch (error) {
      } finally {
        loading();
      }
    }

    const handleMenuClick = (e) => {
      let { key, item } = e
      let { record } = item
      switch (key) {
        case '1':
          router.push(`/storage/inbound/confirm/${record.key}`)
          break;
        case '2':
          router.push(`/storage/inbound/shipping/${record.key}`)
          break;
        case '3':
          router.push(`/storage/inbound/detail/${record.key}`)
          break;
        case '4':
          router.push(`/storage/inbound/detail/${record.key}`)
          break;
        case '5':
          Modal.confirm({
            title: vueI18n.t("common.operation"),
            content: vueI18n.t("common.are_you_sure_cancel"),
            okText: vueI18n.t("common.confirm"),
            cancelText: vueI18n.t("common.cancel"),
            onOk: () => {
              return updateCancelPlan({ planId: record.key, warehouseType: warehouseTypeEnum.storageWarehouse }).then(({ result }) => {
                message.success(vueI18n.t("common.successfully_canceled"));
                handleChangeStatus();
                if (result) {
                  cPlanCanceledAfterModalRef.value.open({
                    name: 'menu.transport_plan',
                    link: '/transport/plandetails/' + result
                  });
                }
              });
            },
          });
          break;
        case '6':
          handleValidationCanCreateTransport(record);
          break;
        default:
          break;
      }
    }

    onActivated(() => {
      getTargetWarehouseList()
      initData()
    });

    return {
      columns,
      ...toRefs(state),
      ...toRefs(data),
      refPage,
      cPlanCanceledAfterModalRef,
      inWarehouseStatusEnum,

      handleExport,
      handlePage,
      handleCreateInPlan,
      handleChangeStatus,
      handleConfirmDate,
      showJumpToTransportModal,
      handleMenuClick,
      refSearchProduct
    };
  },
})
</script>

<style lang="less" scoped>
</style>